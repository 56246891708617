body {
	line-height: 24px;
	font-size: 14px;
}

.header {
	position: static;
	transition: all .2s;
	&.header-fixed {
		position: fixed;
		top: -100px;
		animation: animTop .2s forwards;
	}
}

.flat-wrap-input {
	&.invalid {
		input {
			border-color: red !important;
		}
	}
	&.valid {
		input {
			border-color: green !important;
		}
	}
}

.my-textarea {
	background: rgba(9,27,39,.1);
	border: 1px solid rgba(255,255,255,.15);
	color: #cecece;
}

@keyframes animTop {
	from {
		top: -100px;
	}
	to {
		top: 0px;
	}
}

.tpb__item {
	padding: 100px 0 50px;
	@media (max-width: 768px) {
		text-align: center;
	}
	&--1 {
		background: url('../img/slider/slider-01.jpg') center no-repeat;
		background-size: cover;
	}
	&--2 {
		background: url('../img/slider/slider-02.jpg') center no-repeat;
		background-size: cover;
	}
}

.tpb__title {
    white-space: nowrap;
    color: #fff;
    letter-spacing: 1px;
    font-family: Helvetica;
    line-height: 83px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 700;
	font-size: 73px;
	margin-bottom: 30px;
	@media (max-width: 1200px) {
		font-size: 56px;
		line-height: 60px;
	}
	@media (max-width: 768px) {
		font-size: 24px;
		line-height: 28px;
		white-space: normal;
	}
}

.tpb__text {
    white-space: nowrap;
    color: #fff;
    font-family: Helvetica;
    line-height: 31px;
    font-size: 21px;
    max-height: none;
	max-width: 800px;
	margin-bottom: 20px;
	@media (max-width: 1200px) {
		font-size: 18px;
		line-height: 22px;
	}
	@media (max-width: 768px) {
		font-size: 14px;
		line-height: 22px;
		white-space: normal;
	}
}

.tpb__links {
	display: flex;
	@media (max-width: 768px) {
		justify-content: center;
	}
	@media (max-width: 480px) {
		flex-direction: column;
		align-items: center
	}
}

.tpb__link {
	color: white;
    line-height: 20px;
    font-weight: 700;
	font-size: 14px;
	font-family: Helvetica;
}

.tpb__control {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 40px;
	height: 40px;
	border: 2px solid white;
	cursor: pointer;
	transition: all .2s;
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 15px;
		height: 15px;
		border: 2px solid transparent;
		border-bottom-color: white;
		border-right-color: white;
		border-radius: 2px;
		transition: all .2s;
	}
	&:hover {
		background: white;
		&:before {
			border-bottom-color: black;
			border-right-color: black;
		}
	}
	&--prev {
		left: 10px;
		&:before {
			transform: translate(-40%, -50%) rotate(135deg);
		}
	}
	&--next {
		right: 10px;
		&:before {
			transform: translate(-60%, -50%) rotate(-45deg);
		}
	}
}

.tp-caption {
	&:last-child {
		background-color: rgb(24, 54, 74);
	}
	&+& {
		margin-left: 15px;
	}
	@media (max-width: 480px) {
		&+& {
			margin-left: 0;
			margin-top: 15px;
		}
	}
}